<template>
  <div class="update-import-trade-in">
    <KTCodePreview v-bind:title="'Thông tin phiếu thu cũ'">
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col lg="9" md="9" sm="12">
            <b-row>
              <b-col lg="3" md="3" sm="12">
                <b-form-group>
                  <template>
                    <span>Mã phiếu:</span>
                  </template>
                  <p>{{ stockSlipModel.code }}</p>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <b-form-group>
                  <template>
                    <span>Ngày tạo:</span>
                  </template>
                  <p>{{ stockSlipModel.createdAt }}</p>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <b-form-group>
                  <template>
                    <span>Ngày nhập kho:</span>
                    <span class="text-danger">*</span>
                  </template>
                  <p>{{ stockSlipModel.importDate }}</p>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <b-form-group label="Tổng tiền:">
                  <p>{{ convertPrice(totalPrice) }}</p>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="3" sm="12">
                <b-form-group>
                  <template>
                    <span>Kho:</span>
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    class="mt-2 select-style"
                    v-model="stockSlipModel.storeId"
                    :options="listInventories"
                    size="sm"
                    disabled
                    value-field="id"
                    text-field="name"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <i
                  class="fa fa-download text-primary cursor-pointer"
                  aria-hidden="true"
                  @click="openFileModal()"
                >
                  Quản lý tệp</i
                >
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="3" sm="12">
                <b-form-group>
                  <template>
                    <span>Khách hàng:</span>
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-model="stockSlipModel.customerMobile"
                    size="sm"
                    placeholder="Chọn khách hàng"
                    class="input-style text-left mt-2"
                    disabled
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback"
                    >Vui lòng chọn nhà cung cấp</b-form-invalid-feedback
                  >
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Họ tên KH: </label>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="stockSlipModel.customerName"
                    placeholder="Họ tên khách hàng"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Địa chỉ:</label>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="stockSlipModel.customerAddress"
                    placeholder="Địa chỉ khách hàng"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <label>Căn cước/chứng minh:</label>
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="stockSlipModel.customerIdentityNo"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="9" md="9" sm="12">
                <b-form-group>
                  <template>
                    <span>Nội dung:</span>
                  </template>
                  <b-form-textarea
                    size="sm"
                    v-model="stockSlipModel.description"
                    :placeholder="'Thêm nội dung...'"
                    :rows="3"
                    :max-rows="3"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="12">
                <b-form-group>
                  <template>
                    <span>Người nhập:</span>
                  </template>
                  <p>{{ stockSlipModel.createByName }}</p>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="3" sm="12">
                <b-form-select
                  class="select-style"
                  v-model="selectTypeSearch"
                  :options="listTypeSearch"
                  size="sm"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </b-col>
              <b-col lg="9" md="9" sm="12">
                <Autosuggest
                  :model="searchProduct"
                  :suggestions="filteredOptionsProduct"
                  placeholder="tên sản phẩm"
                  :limit="200"
                  @select="onSelectedProduct"
                  @change="onInputChangProduct"
                  suggestionName="productName"
                />
              </b-col>
            </b-row>
          </b-col>

          <b-col lg="3" md="3" sm="12">
            <StockSlipPayment
              :storeId="stockSlipModel.storeId"
              :stockSlipModel="stockSlipModel"
              v-if="renderComponent"
              :isSubmit="isSubmit"
            />
          </b-col>
        </b-row>
        <span class="font-weight-bolder">Danh sách sản phẩm:</span>
        <table
          class="table table-bordered table-vertical-center table-hover col-12 mt-4"
        >
          <thead>
            <tr>
              <th scope="col" class="title-center" style="width: 15%">
                Tên sản phẩm
              </th>
              <th scope="col" class="title-center" style="width: 5%">Tồn</th>
              <th scope="col" class="title-center" style="width: 20%">IMEI</th>
              <th scope="col" class="title-center" style="width: 10%">
                Số lượng
              </th>
              <th scope="col" class="title-center" style="width: 18%">
                Đơn giá
              </th>
              <th scope="col" class="title-center" style="width: 14%">
                Thành tiền
              </th>
              <th scope="col" class="title-center" style="width: 13%">
                Chiết khấu
              </th>
              <th scope="col" class="title-center" style="width: 5%"></th>
            </tr>
          </thead>

          <tbody v-for="item in stockSlipModel.details" :key="item.id">
            <ImportStock
              v-bind:productItem="item"
              v-bind:editState="true"
              v-on:deleteItem="deleteItemInArray"
              v-on:update="updateItem"
            />
          </tbody>
          <tr>
            <td :colspan="3" class="tdTextAlignPrice">Tổng</td>
            <td class="tdTextAlignPrice">
              {{ convertPrice(totalQuantity) }}
            </td>
            <td></td>
            <td class="tdTextAlignPrice">{{ convertPrice(totalPrice) }}</td>
            <td class="tdTextAlignPrice">
              {{ convertPrice(totalDiscount) }}
            </td>
            <td></td>
          </tr>
        </table>

        <hr class="hr-text" data-content="Bút Toán" style="font-weight: 600" />
        <b-row v-if="stockSlipModel.code !== ''">
          <b-col md="12" lg="12" sm="12">
            <StockSlipTransactions :stockCode="stockSlipModel.code" />
          </b-col>
        </b-row>
        <b-modal
          ref="file-modal"
          size="lg"
          hide-footer
          title="Chỉnh sửa tệp"
          id="file-modal"
        >
          <b-row>
            <b-col cols="12">
              <UploadFile :id="relatedStockCode" :entity="'evaluation-trade'" :isAllowUpload="false"/>
            </b-col>
          </b-row>
        </b-modal>
      </template>
      <template v-slot:foot>
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          @click="onSubmit"
          >Lưu</b-button
        >
        <b-button
          style="margin-left: 10px; font-weight: 600; width: 100px"
          variant="primary"
          size="sm"
          @click="exportExcel"
          >Xuất Excel</b-button
        >
        <router-link to="/stocks" tag="button">
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            >Hủy</b-button
          >
        </router-link>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import ImportStock from '@/view/components/ImportStock';
import StockSlipTransactions from '@/view/components/StockSlipTransactions';
import { validationMixin } from 'vuelidate';
import {
  makeToastSuccess,
  unMaskPrice,
  convertPrice,
  makeToastFaile,
} from '@/utils/common';
import axios from 'axios';
import { BASE_URL, TIME_TRIGGER } from '@/utils/constants';
import decounce from 'debounce';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { getToastInstance } from '@/utils/toastHelper';
import UploadFile from '@/view/modules/upload/Upload';
import StockSlipPayment from '../../components/stock-slips/StockSlipPayment';

export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        inventorySelected: null,
        providerSelected: null,
        dateImport: '',
      },
      relatedStockCode: null,
      isNew: true,
      listInventories: [],
      filteredOptionsProduct: [],
      optionsProducts: [],
      searchProduct: '',
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
      ],
      selectTypeSearch: 1,
      selectedClassProduct: null,
      isSearching: false,
      entityUploadId: null,
      stockSlipModel: {
        id: null,
        code: '',
        storeId: null,
        totalAmount: 0,
        customerId: null,
        customerName: '',
        customerMobile: '',
        providerId: null,
        createdAt: '',
        importDate: '',
        customerAddress: '',
        customerIdentityNo: '',
        description: '',
        createByName: '',
        paymentInfo: {
          cashAmount: 0,
          cashAccountId: null,
          cashAccountCode: '',
          cashAccountName: '',
          transferAmount: 0,
          transferAccountId: null,
          transferAccountCode: '',
          transferAccountName: '',
        },
        details: [],
      },
      renderComponent: false,
      isSubmit: false,
    };
  },
  validations: {},
  components: {
    KTCodePreview,
    ImportStock,
    StockSlipTransactions,
    Autosuggest,
    UploadFile,
    StockSlipPayment,
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.stockSlipModel.details.length; index++) {
        const element = this.stockSlipModel.details[index];
        total +=
          unMaskPrice(element.price) * element.quantity -
          unMaskPrice(element.discount);
      }
      return total;
    },
    totalDiscount() {
      let total = 0;
      for (let index = 0; index < this.stockSlipModel.details.length; index++) {
        const element = this.stockSlipModel.details[index];
        total += unMaskPrice(element.discount) * 1;
      }
      return total;
    },
    totalQuantity() {
      let total = 0;
      for (let index = 0; index < this.stockSlipModel.details.length; index++) {
        const element = this.stockSlipModel.details[index];
        total += element.quantity * 1;
      }
      return total;
    },
  },
  created() {
    this.fetchStore();
    this.entityUploadId = this.$route.query.id;
    this.getStockById();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu thu cũ', route: '/import-trade-in' },
      { title: 'Cập nhật phiếu thu cũ' },
    ]);
  },
  methods: {
    openFileModal() {
      this.$refs['file-modal'].show();
    },
    convertPrice,
    ...getToastInstance(),
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    onSelectedProduct(option) {
      this.selectedClassProduct = option.item;
      this.searchProduct = option.item.productName;
      let quantity = 0;
      if (this.selectedClassProduct.productType === 1) {
        quantity = 1;
      }
      const pro = {
        id: this.$uuid.v4(),
        name: this.selectedClassProduct.productName,
        totalQuantityInStock:
          this.selectedClassProduct.quantityInStock != null
            ? this.selectedClassProduct.quantityInStock
            : 0,
        IMEI: '',
        proId: this.selectedClassProduct.productId,
        price: this.selectedClassProduct.originalPrice,
        quantity: quantity,
        totalPrice: quantity * this.selectedClassProduct.originalPrice,
        productType: this.selectedClassProduct.productType,
        discount: 0,
      };
      if (this.selectedClassProduct.productType === 3) {
        makeToastFaile('Không hỗ trợ nhập kho sản phẩm combo');
      } else {
        this.stockSlipModel.details.push(pro);
      }
      this.searchProduct = '';
    },
    onInputChangProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInputProduct();
    },
    getStockById() {
      ApiService.get(`stocks/${this.$route.query.id}`)
        .then(({ data }) => {
          const dataRes = data.data;
          this.stockSlipModel = {
            id: dataRes.id,
            code: dataRes.code,
            storeId: dataRes.storeId,
            totalAmount: dataRes.totalAmount,
            customerId: dataRes.providerId,
            customerName: dataRes.providerName,
            customerMobile: dataRes.customerPhone,
            providerId: dataRes.providerId,
            createdAt: moment(dataRes.createdAt).format('HH:mm DD/MM/YYYY'),
            importDate: moment(dataRes.importDate).format('DD-MM-YYYY'),
            customerAddress: dataRes.address,
            customerIdentityNo: dataRes.customerIdentityNo,
            description: dataRes.description,
            createByName: dataRes.createdBy,
            paymentInfo: {
              cashAmount: dataRes.cashAmount,
              cashAccountId: null,
              cashAccountCode: dataRes.cashAccountCode,
              cashAccountName: dataRes.cashAccName,
              isValidCash: true,
              transferAmount: dataRes.transferAmount,
              transferAccountId: null,
              transferAccountCode: dataRes.transferAccountCode,
              transferAccountName: dataRes.transferAccName,
              isValidTransfer: true,
            },
            details: [],
          };
          this.stockSlipModel.details = dataRes.listDetail.map((element) => {
            let listImei = element.productImei.split(',');
            let stt = 1;
            let formatImei = '';
            listImei.forEach((element, index) => {
              formatImei += stt + ')  ' + element;
              if (index !== listImei.length - 1) {
                formatImei += ',';
              }
              stt++;
            });
            return {
              id: element.id,
              name: element.productName,
              IMEI: element.productImei
                .replace(/[,]/g, '\n')
                .replace(/[ ]/g, ''),
              proId: element.productId,
              price: element.unitPrice,
              quantity: element.quantity,
              totalPrice: element.totalAmount,
              productType: element.productType,
              formatImei: formatImei.replace(/[,]/g, '\n').replace(/[ ]/g, ''),
              totalQuantityInStock:
                element.totalQuantityInStock != null
                  ? element.totalQuantityInStock
                  : 0,
              discount: element.discount,
            };
          });
          this.renderComponent = true;
        })
        .catch((response) => {
          makeToastFaile(response.$error);
        });
    },
    fetchStore() {
      ApiService.get(`stores?limit=10000&page=1`).then(({ data }) => {
        this.listInventories = data.data.list_store;
      });
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      ApiService.get(
        `productSearch?storeId=${this.stockSlipModel.storeId}&searchProduct=${textSearch}`,
      ).then(({ data }) => {
        this.optionsProducts = data.data;
        this.filteredOptionsProduct = [...this.optionsProducts];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          let nextSearchProduct = this.searchProduct;
          this.fetchProduct(nextSearchProduct);
        }
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.stockSlipModel.details.length; i++) {
        if (this.stockSlipModel.details[i].id === id) {
          this.stockSlipModel.details.splice(i, 1);
        }
      }
    },
    updateItem(item) {
      this.stockSlipModel.details.forEach((element, index) => {
        if (element.id === item.id) {
          this.stockSlipModel.details[index].name = item.name;
          this.stockSlipModel.details[index].IMEI = item.IMEI;
          this.stockSlipModel.details[index].proId = item.proId;
          this.stockSlipModel.details[index].price = unMaskPrice(item.price);
          this.stockSlipModel.details[index].quantity = item.quantity;
          this.stockSlipModel.details[index].totalPrice = item.totalPrice;
          this.stockSlipModel.details[index].discount = unMaskPrice(
            item.discount,
          );
        }
      });
    },
    onSubmit: async function () {
      this.isNew = false;
      this.isSubmit = true;
      this.stockSlipModel.paymentInfo.isValidCash = this.validateCash();
      this.stockSlipModel.paymentInfo.isValidTransfer = this.validateTransfer();
      if (
        !this.stockSlipModel.paymentInfo.isValidCash ||
        !this.stockSlipModel.paymentInfo.isValidTransfer
      ) {
        return makeToastFaile('Vui lòng chọn thông tin thanh toán hợp lệ!');
      }
      const listPro = this.stockSlipModel.details.map((element) => {
        return {
          id: element.id,
          productId: element.proId,
          imeis: element.IMEI.replace(/[\n\r]/g, ', '),
          quantity: element.quantity,
          unitPrice: unMaskPrice(element.price),
          discount: unMaskPrice(element.discount),
        };
      });
      const data = {
        id: this.stockSlipModel.id,
        storeId: this.stockSlipModel.storeId,
        providerId: this.stockSlipModel.providerId,
        description: this.stockSlipModel.description,
        importDate: this.stockSlipModel.importDate
          ? moment(this.stockSlipModel.importDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD',
            )
          : '',
        listProduct: listPro,
        cashAmount: unMaskPrice(this.stockSlipModel.paymentInfo.cashAmount),
        cashAccountCode: this.stockSlipModel.paymentInfo.cashAccountCode,
        transferAmount: unMaskPrice(
          this.stockSlipModel.paymentInfo.transferAmount,
        ),
        transferAccountCode:
          this.stockSlipModel.paymentInfo.transferAccountCode,
      };
      ApiService.put('stock-slips/import-tradein', data)
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.$router.push({
              path: '/import-trade-in',
            });
          } else {
            makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    exportExcel: async function () {
      let baseUrl = BASE_URL;
      let url = `${baseUrl}stocks/export-excel/${this.stockSlipModel.id}?type=1`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let filename = headerLine.substring(
          startFileNameIndex,
          endFileNameIndex,
        );
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        // let fileName = 'Hóa đơn nhập NCC';
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    debounceInputProduct: decounce(function () {
      this.nextSearchProduct();
    }, TIME_TRIGGER),
    nextSearchProduct() {
      if (!this.isSearching) {
        const textSearch = this.searchProduct;
        this.fetchProduct(textSearch);
      }
    },
    validateCash() {
      const cashAmount = unMaskPrice(
        this.stockSlipModel.paymentInfo.cashAmount,
      );
      if (
        parseInt(cashAmount) > 0 &&
        !this.stockSlipModel.paymentInfo.cashAccountCode
      ) {
        return false;
      }
      return true;
    },
    validateTransfer() {
      const transferAmount = unMaskPrice(
        this.stockSlipModel.paymentInfo.transferAmount,
      );
      if (
        parseInt(transferAmount) > 0 &&
        !this.stockSlipModel.paymentInfo.transferAccountCode
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-1.scss';

.update-import-trade-in {
  .input-group-append {
    cursor: pointer;
    padding-top: 5.5px;
  }

  .input-group-append:hover i {
    color: #3699ff;
  }

  .tdTextAlignPrice {
    text-align: right !important;
    vertical-align: middle;
    font-weight: 600;
  }
  .title-center {
    text-align: center;
  }
}
</style>
