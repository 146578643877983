<template>
  <div class="stockslip-payment">
    <b-row>
      <b-col>
        <b-form-group>
          <label>Tiền mặt</label>
          <b-form-input
            class="input-style text-right"
            size="sm"
            type="text"
            v-model="stockSlipModel.paymentInfo.cashAmount"
            placeholder="Nhập số tiền mặt"
            v-mask="mask"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <label>Tài khoản tiền mặt:</label>
          <Autosuggest
            class="border-radius-none"
            :model="handleSearchCash"
            :suggestions="filteredOptionsCash"
            placeholder="tài khoản tiền mặt"
            :limit="200"
            @select="onSelected($event, 'cash')"
            @change="onInputChangeCash"
            :disabled="!filteredOptionsCash.length"
            suggestionName="suggestionName"
          />
          <b-form-invalid-feedback
            :state="stockSlipModel.paymentInfo.isValidCash"
            id="input-cash-live-feedback"
            >Vui lòng chọn tài khoản tiền mặt</b-form-invalid-feedback
          >
        </b-form-group>
      </b-col>
    </b-row>
    <b-button
      variant="secondary"
      size="sm"
      block
      class="font-weight-bolder mb-2"
      @click="showApprovalModal(1)"
    >
      <i class="fa fa-plus-circle"></i>
      Tạo duyệt chi</b-button
    >
    <b-row>
      <b-col>
        <b-form-group>
          <label>Tiền chuyển khoản</label>
          <b-form-input
            class="input-style text-right"
            size="sm"
            type="text"
            v-model="stockSlipModel.paymentInfo.transferAmount"
            required
            placeholder="Nhập số tiền"
            v-mask="mask"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <label>Tài khoản chuyển khoản:</label>
          <Autosuggest
            :model="stockSlipModel.paymentInfo.transferAccountName"
            :suggestions="filteredOptionsTransfer"
            placeholder="tài khoản chuyển khoản"
            :limit="200"
            @select="onSelected($event, 'transfer')"
            @change="onInputChangeTransfer"
            suggestionName="suggestionName"
          />
          <b-form-invalid-feedback
            :state="stockSlipModel.paymentInfo.isValidTransfer"
            id="input-transfer-live-feedback"
            >Vui lòng chọn tài khoản chuyển khoản</b-form-invalid-feedback
          >
        </b-form-group>
      </b-col>
    </b-row>
    <b-button
      variant="secondary"
      size="sm"
      block
      class="font-weight-bolder mb-2"
      @click="showApprovalModal(2)"
    >
      <i class="fa fa-plus-circle"></i>
      Tạo duyệt chi</b-button
    >
    <ApprovalModal ref="popup-approval" />
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import { map } from 'lodash';
import { convertPrice, currencyMask, unMaskPrice } from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import ApprovalModal from '@/view/components/approval/ApprovalModal.vue';
import { APPROVAL_PAYMENT_TYPE, APPROVAL_TYPE_ENUM } from '@/utils/enum';

export default {
  props: ['storeId', 'stockSlipModel'],
  data() {
    return {
      mask: currencyMask,
      filteredOptionsCash: [],
      cashAccountOptions: [],
      filteredOptionsTransfer: [],
      transferAccountOptions: [],
      approvalPaymentType: APPROVAL_PAYMENT_TYPE,
    };
  },
  components: {
    Autosuggest,
    ApprovalModal,
  },
  watch: {},
  computed: {
    handleSearchCash() {
      return this.filteredOptionsCash.length > 0
        ? this.stockSlipModel.paymentInfo.cashAccountName
        : 'Không có thông tin loại tài khoản tiền mặt';
    },
  },
  created() {
    this.fetchCashAccountants();
    this.fetchTransferAccountants();
  },
  methods: {
    convertPrice,
    fetchCashAccountants: async function () {
      this.accountantOptions = [];
      const url = 'accountants/getByStore';
      const params = {
        type: 1,
        storeId: this.storeId,
      };
      await ApiService.query(url, {
        params,
      }).then((response) => {
        const data = response.data.data || [];
        map(data, (item) => {
          const element = {
            id: item.id,
            code: item.code,
            name: item.name,
            suggestionName: `(${item.code}) - ${item.name}`,
          };
          this.cashAccountOptions.push(element);
        });
        this.filteredOptionsCash = [...this.cashAccountOptions];
      });
    },
    fetchTransferAccountants: async function () {
      this.accountantOptions = [];
      const params = {
        type: 2,
      };
      const url = cmdUrl.AccountantUrl.byType;
      await ApiService.query(url, {
        params,
      }).then((response) => {
        const data = response.data.data || [];
        map(data, (item) => {
          const element = {
            id: item.id,
            code: item.code,
            name: item.name,
            suggestionName: `(${item.code}) - ${item.name}`,
          };
          this.transferAccountOptions.push(element);
        });
        this.filteredOptionsTransfer = [...this.transferAccountOptions];
      });
    },
    validateCash() {
      const cashAmount = unMaskPrice(
        this.stockSlipModel.paymentInfo.cashAmount,
      );
      if (
        parseInt(cashAmount) > 0 &&
        !this.stockSlipModel.paymentInfo.cashAccountCode
      ) {
        return false;
      }
      return true;
    },
    validateTransfer() {
      const transferAmount = unMaskPrice(
        this.stockSlipModel.paymentInfo.transferAmount,
      );
      if (
        parseInt(transferAmount) > 0 &&
        !this.stockSlipModel.paymentInfo.transferAccountCode
      ) {
        return false;
      }
      return true;
    },
    onSelected(option, type) {
      switch (type) {
        case 'cash':
          this.stockSlipModel.paymentInfo.cashAccountId = option.item.id;
          this.stockSlipModel.paymentInfo.cashAccountCode = option.item.code;
          this.stockSlipModel.paymentInfo.cashAccountName = option.item.name;
          break;
        case 'transfer':
          this.stockSlipModel.paymentInfo.transferAccountId = option.item.id;
          this.stockSlipModel.paymentInfo.transferAccountCode =
            option.item.code;
          this.stockSlipModel.paymentInfo.transferAccountName =
            option.item.name;
          break;
      }
    },
    onInputChangeCash(text) {
      this.stockSlipModel.paymentInfo.cashAccountName = text;
      const filteredData = this.cashAccountOptions
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsCash = [...filteredData];
    },
    onInputChangeTransfer(text) {
      this.stockSlipModel.paymentInfo.transferAccountName = text;
      const filteredData = this.transferAccountOptions
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsTransfer = [...filteredData];
    },
    showApprovalModal(paymentType) {
      const data = {
        amount:
          paymentType === this.approvalPaymentType.CASH
            ? unMaskPrice(this.stockSlipModel.paymentInfo.cashAmount)
            : unMaskPrice(this.stockSlipModel.paymentInfo.transferAmount),
        customerId: this.stockSlipModel.customerId,
        customerName: this.stockSlipModel.customerName,
        customerMobile: this.stockSlipModel.customerMobile,
        storeId: this.stockSlipModel.storeId,
        paymentType: paymentType,
      };
      this.$refs['popup-approval'].showModal(
        this.stockSlipModel.code,
        data,
        APPROVAL_TYPE_ENUM.BUY_OLD,
      );
    },
  },
};
</script>
<style lang="scss">
.stockslip-payment {
  .form-group {
    margin-bottom: 1.5rem;
  }
}
</style>